import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Pagenation } from '../components/Pagenation';
import { Seo } from '../components/Seo';
import { ArticleList } from '../components/ArticleList';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { MODULE_TITLE, META_INFO } from '../utils/word';
import { Ad_PayPay } from '../ad/AdPayPay';
import { Ad_Amazon } from '../ad/AdAmazon';

export default ({ data, location, pageContext }): JSX.Element => (
  <Layout>
    <Seo
      pagetitle={META_INFO.ARTICLE_LIST.TITLE}
      pagedesc={META_INFO.ARTICLE_LIST.DESCRIPTION}
      pagepath={location.pathname}
    />
    <CommonContent>
      <CommonBlock title={MODULE_TITLE.RECENT_ARTICLE}>
        <ArticleList data={data} />
        <Pagenation pageContext={pageContext} type="all" />
      </CommonBlock>

      <Ad_PayPay />
      <Ad_Amazon />
    </CommonContent>
  </Layout>
);

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;
