import React from 'react';
import styled from '@emotion/styled';

const Ad_Amazon = (): JSX.Element => {
  return (
    <Div_AdWrap>
      <a
        href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3566916&pid=886939507"
        rel="nofollow"
      >
        <img
          src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3566916&pid=886939507"
          alt=""
        />
      </a>
    </Div_AdWrap>
  );
};

const Div_AdWrap = styled.div`
  max-width: 600px;
  margin: 32px auto;
  padding: 0 16px;
`;

export { Ad_Amazon };
